import {Fragment, h} from "../_snowpack/pkg/preact.js";
import {Link} from "../_snowpack/pkg/react-router-dom.js";
export default function Certified() {
  return /* @__PURE__ */ h(Fragment, null, /* @__PURE__ */ h("div", {
    className: "container mt-24 lg:mt-36 xl:mt-72"
  }, /* @__PURE__ */ h("h1", {
    className: "text-xxl"
  }, "Certified!"), /* @__PURE__ */ h("div", {
    className: "xl:grid xl:grid-cols-12 xl:mt-52"
  }, /* @__PURE__ */ h("div", {
    className: "xl:col-span-3 2xl:col-span-5 pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "w-[112px] md:w-[186px] lg:w-[279px] xl:w-[237px] 2xl:w-[358px] mt-8 md:mt-12 lg:mt-20 xl:mt-0 mb-10 md:mb-14 lg:mb-24 xl:mb-0",
    src: "/static/candle.png",
    alt: "Candle"
  })), /* @__PURE__ */ h("div", {
    className: "xl:col-span-9 2xl:col-span-7"
  }, /* @__PURE__ */ h("div", {
    className: "grid grid-cols-12 gap-x-5 md:gap-10 border-t border-grey-light"
  }, /* @__PURE__ */ h("div", {
    className: "col-span-5 md:col-span-4 lg:col-span-6 pt-4"
  }, /* @__PURE__ */ h("h2", {
    className: "text-h3 max-w-xs"
  }, "How we’ve lived our first year as a B Corp")), /* @__PURE__ */ h("div", {
    className: "col-span-7 md:col-span-8 lg:col-span-6 pt-5"
  }, /* @__PURE__ */ h("p", null, "The global pandemic turned 2020 into a year nobody wants to remember but no one will ever forget. We had to become agile in the way we worked - exhausting, and bloody hard, but a challenge that has forced us to think differently, act faster, and made us more resilient for what comes next."))))), /* @__PURE__ */ h("div", {
    className: "2xl:w-10/12 border-t border-grey-light pt-4 mt-24 md:mt-28 xl:mt-48"
  }, /* @__PURE__ */ h("h3", null, "What a year"), /* @__PURE__ */ h("p", {
    className: "text-h2 mt-16 md:mt-24 xl:mt-48"
  }, "It’s been almost 12 months since we became certified, and we’re so proud of what we have achieved. Now, we’re moving forward, even stronger and more determined in our commitment.")), /* @__PURE__ */ h("div", {
    className: "relative"
  }, /* @__PURE__ */ h("svg", {
    className: "absolute -z-1 -bottom-20 md:-bottom-24 lg:-bottom-32 xl:-bottom-56 2xl:-bottom-44 right-0 w-[275px] md:w-[448px] lg:w-[651px] xl:w-[807px] 2xl:w-[773px]",
    width: "775",
    viewBox: "0 0 775 424",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    opacity: "0.3",
    d: "M212 423C328.532 423 423 328.532 423 212C423 95.4679 328.532 1 212 1C95.4679 1 1 95.4679 1 212C1 328.532 95.4679 423 212 423Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    opacity: "0.3",
    d: "M563 423C679.532 423 774 328.532 774 212C774 95.4679 679.532 1 563 1C446.468 1 352 95.4679 352 212C352 328.532 446.468 423 563 423Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }))), /* @__PURE__ */ h("div", {
    className: "xl:grid grid-cols-12 xl:gap-x-24 mt-32 md:mt-36 lg:mt-56 xl:mt-72"
  }, /* @__PURE__ */ h("div", {
    className: "w-8/12 lg:w-6/12 xl:w-auto xl:col-span-4 border-t border-grey-light pt-4"
  }, /* @__PURE__ */ h("h3", {
    className: "text-xl"
  }, "For our people")), /* @__PURE__ */ h("div", {
    className: "xl:col-span-8"
  }, /* @__PURE__ */ h("div", {
    className: "md:grid md:grid-cols-2 2xl:grid-cols-3 md:gap-x-20 xl:gap-x-24 mt-14 md:mt-24 xl:mt-0 lg:w-10/12 xl:w-auto ml-auto"
  }, /* @__PURE__ */ h("div", {
    className: "md:col-span-1 2xl:col-span-1 2xl:col-start-2 border-t border-grey-light pt-4 2xl:order-1"
  }, /* @__PURE__ */ h("h4", {
    className: "text-h3"
  }, "Greater mental health initiatives"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-7 2xl:mt-10"
  }, "Access to a trained counsellor, a buddy system, group mindfulness sessions and Zoom Workouts with a local PT instructor.")), /* @__PURE__ */ h("div", {
    className: "md:col-span-1 2xl:col-span-1 border-t border-grey-light pt-4 mt-9 md:mt-0 2xl:order-2"
  }, /* @__PURE__ */ h("h4", {
    className: "text-h3"
  }, "Social events for team bonding"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-7 2xl:mt-10"
  }, "Culture Club, games nights, meditation sessions, virtual lunch breaks and more!")), /* @__PURE__ */ h("div", {
    className: "md:col-span-1 2xl:col-span-1 2xl:col-start-2 border-t md:border-0 border-grey-light pt-4 md:pt-0 mt-9 md:mt-16 2xl:mt-24 2xl:order-3"
  }, /* @__PURE__ */ h("h4", {
    className: "text-h3"
  }, "Enhanced training opportunities"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-7 2xl:mt-10"
  }, "Shopify training, Animation skills with Adobe After Effects, 3D software training using Blender and Cinema 4D,", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.interaction-design.org/"
  }, "Interaction Design Foundation"), " ", "& presentation skills training.")), /* @__PURE__ */ h("div", {
    className: "md:col-span-1 2xl:col-span-1 border-t md:border-0 border-grey-light pt-4 md:pt-0 mt-9 md:mt-16 2xl:mt-24 2xl:order-4"
  }, /* @__PURE__ */ h("h4", {
    className: "text-h3"
  }, "Support our team at home"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-7 2xl:mt-10"
  }, "Making sure they have the right hardware, software, and equipment to feel comfortable.")), /* @__PURE__ */ h("div", {
    className: "md:col-span-1 2xl:col-span-1 2xl:col-start-2 border-t md:border-0 border-grey-light pt-4 md:pt-0 mt-9 md:mt-16 2xl:mt-24 2xl:order-6"
  }, /* @__PURE__ */ h("h4", {
    className: "text-h3"
  }, "Support for parents in our team"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-7 2xl:mt-10"
  }, "Improving our maternity and paternity packages for the growing number of parents within our team.")), /* @__PURE__ */ h("div", {
    className: "md:col-span-1 2xl:col-span-1 border-t md:border-0 border-grey-light pt-4 md:pt-0 mt-9 md:mt-16 2xl:mt-24 2xl:order-7"
  }, /* @__PURE__ */ h("h4", {
    className: "text-h3"
  }, "The Growyo allotment"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-7 2xl:mt-10"
  }, "A", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.instagram.com/growyo_/"
  }, "space"), " ", "for social gatherings and producing fruit and veg which is used by the whole team.")), /* @__PURE__ */ h("div", {
    className: "md:col-span-1 md:col-start-2 2xl:col-span-1 2xl:col-start-1 2xl:row-start-3 border-t md:border-0 border-grey-light pt-4 md:pt-0 mt-9 md:mt-16 2xl:mt-24 2xl:order-5"
  }, /* @__PURE__ */ h("h4", {
    className: "text-h3"
  }, "Introducing more flexibility"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-7 2xl:mt-10"
  }, "Exploring options to allow people to balance their home and work lives through remote working and increase flexibility."))))), /* @__PURE__ */ h("div", {
    className: "relative pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "w-[138px] md:w-[210px] xl:w-[343px] 2xl:w-[358px] mt-14 md:absolute md:-top-24 lg:-top-40 xl:-top-96 md:left-0 ",
    src: "/static/mushrooms.png",
    alt: "Mushrooms"
  })), /* @__PURE__ */ h("div", {
    className: "lg:w-10/12 xl:w-full 2xl:w-10/12 mt-20 md:mt-44 lg:mt-48 xl:mt-52 2xl:mt-72 ml-auto"
  }, /* @__PURE__ */ h("h4", {
    className: "mb-5"
  }, "Our planet"), /* @__PURE__ */ h("ul", null, /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Ditched plastic"), /* @__PURE__ */ h("div", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, /* @__PURE__ */ h("p", null, "We’ve invested in", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.chillysbottles.com/uk"
  }, "Chilly’s"), " ", "bottles for the team. We’re utilising local re-fill stations for our snacks and we order our milk in glass bottles!", " "))), /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Shop local"), /* @__PURE__ */ h("div", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, /* @__PURE__ */ h("p", null, /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.oddbox.co.uk/"
  }, "OddBox"), "; ‘Rescuing odd and surplus fruit & veg directly from the farm and helping save the planet.’"), /* @__PURE__ */ h("p", {
    className: "mt-5"
  }, /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://uk.whogivesacrap.org/"
  }, "Who Gives a Crap"), "; A fellow B-Corp, selling toilet paper and donating 50% of their profits to help build toilets and improve sanitation in the developing world."), /* @__PURE__ */ h("p", {
    className: "mt-5"
  }, "Local beer/wine/gin suppliers such as:", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://goodthingsbrewing.co/"
  }, "Good Things Brewery"), ",", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://annodistillers.co.uk/"
  }, "Anno Gin Distillery"), " ", "and", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://tillingham.com"
  }, "Tillingham Wines")), /* @__PURE__ */ h("p", {
    className: "mt-5"
  }, /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.containyourselftw.com/"
  }, "Contain Yourself"), " ", "and", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.thezerowastecompany.com/"
  }, "Zero Waste"), " ", "- helping people in the Tunbridge Wells area to ditch plastic, shop local and use eco friendly products."))), /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Carbon neutral"), /* @__PURE__ */ h("div", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, /* @__PURE__ */ h("p", null, "We have achieved carbon neutrality through ‘Go Climate Neutral Now’. We chose to make donations towards the installation of a wind power project in Rajasthan and Tamil Nadu, India.")))))), /* @__PURE__ */ h("div", {
    className: "mt-24 md:mt-36 xl:mt-72 pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "block w-full",
    src: "/static/certified.jpg",
    alt: "Windfarm"
  })), /* @__PURE__ */ h("div", {
    className: "container"
  }, /* @__PURE__ */ h("div", {
    className: "border-t border-grey-light mt-24 md:mt-36 lg:mt-56 xl:mt-72 pt-4"
  }, /* @__PURE__ */ h("h3", {
    className: "text-h3"
  }, "Being a force for change"), /* @__PURE__ */ h("div", {
    className: "lg:grid lg:grid-cols-2 xl:grid-cols-3 lg:gap-x-24 mt-20 md:mt-24 xl:mt-48"
  }, /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 border-t border-grey-light pt-4"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "50% increase of women on management team"), /* @__PURE__ */ h("p", {
    className: "mt-20 2xl:mt-12"
  }, "Recruiting two incredible women to run our Growth and Delivery teams.")), /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-10 lg:mt-0"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Supporting the BAME community"), /* @__PURE__ */ h("p", {
    className: "mt-20 2xl:mt-12"
  }, "Our CEO Jenny has joined the", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://kingscross.impacthub.net/new-roots/"
  }, "New Roots Mentoring Programme"), ", a peer-to-peer support programme for people from a BAME background interested in entrepreneurship.")), /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 xl:col-start-1 border-t border-grey-light pt-4 mt-10 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(3em*0.9)]"
  }, "Supporting our local community"), /* @__PURE__ */ h("p", {
    className: "mt-20 2xl:mt-12"
  }, "Donating to our local food bank,", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.nourishcommunityfoodbank.org.uk/"
  }, "Nourish"), " ", "and litter picking in local beauty spots.")), /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-10 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(3em*0.9)]"
  }, "Raising money for charity"), /* @__PURE__ */ h("p", {
    className: "mt-20 2xl:mt-12"
  }, "Including a 5k walk for MIND - 5K your way and Christmas Jumper Day for Save The Children.")), /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-10 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(3em*0.9)]"
  }, "Supporting young people"), /* @__PURE__ */ h("p", {
    className: "mt-20 2xl:mt-12"
  }, "We took on two amazing interns, one of which we have now employed full time!")), /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-10 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(3em*0.9)]"
  }, "Promoting the B-Corp movement"), /* @__PURE__ */ h("p", {
    className: "mt-20 2xl:mt-12"
  }, "Our CEO has become a B-Corp Ambassador. Jen has spoken at a number of events and helped several companies through the B Corp assessment.")), /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-10 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(3em*0.9)]"
  }, "Pro bono work"), /* @__PURE__ */ h("p", {
    className: "mt-20 2xl:mt-12"
  }, "We’re very proud to have supported our local council with a ‘Stay Home’ campaign during the pandemic.")))), /* @__PURE__ */ h("div", {
    className: "relative pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "block w-[129px] md:w-[153px] lg:w-[234px] mt-4 md:mt-20 lg:-mt-64 xl:-mt-32 ml-auto lg:absolute lg:top-0 lg:right-0 xl:right-32",
    src: "/static/leaves.png",
    alt: "Leaves"
  })), /* @__PURE__ */ h("div", {
    className: "relative mt-44 md:mt-64 lg:mt-80 xl:mt-96 mx-auto max-w-[1248px] pointer-events-none select-none"
  }, /* @__PURE__ */ h("h2", {
    className: "absolute z-30 top-1/2 left-0 w-full transform -translate-y-1/2 text-center text-doing"
  }, "Doing it"), /* @__PURE__ */ h("svg", {
    className: "relative -z-1 block mx-auto w-[74.84%]",
    width: "934",
    viewBox: "0 0 934 934",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("g", {
    opacity: "0.2"
  }, /* @__PURE__ */ h("path", {
    d: "M464 933C688.228 933 870 724.365 870 467C870 209.635 688.228 1 464 1C239.772 1 58 209.635 58 467C58 724.365 239.772 933 464 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M464 933C655.091 933 810 724.365 810 467C810 209.635 655.091 1 464 1C272.909 1 118 209.635 118 467C118 724.365 272.909 933 464 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M464 933C621.953 933 750 724.365 750 467C750 209.635 621.953 1 464 1C306.047 1 178 209.635 178 467C178 724.365 306.047 933 464 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M464 933C588.816 933 690 724.365 690 467C690 209.635 588.816 1 464 1C339.184 1 238 209.635 238 467C238 724.365 339.184 933 464 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M464 933C555.679 933 630 724.365 630 467C630 209.635 555.679 1 464 1C372.321 1 298 209.635 298 467C298 724.365 372.321 933 464 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M464 933C522.542 933 570 724.365 570 467C570 209.635 522.542 1 464 1C405.458 1 358 209.635 358 467C358 724.365 405.458 933 464 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M467 933C492.405 933 513 724.365 513 467C513 209.635 492.405 1 467 1C441.595 1 421 209.635 421 467C421 724.365 441.595 933 467 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M467 1V933",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M0.999982 466.999C0.999972 691.227 209.635 872.999 467 872.999C724.365 872.999 933 691.227 933 466.999C933 242.771 724.365 60.999 467 60.999C209.635 60.999 0.999992 242.771 0.999982 466.999Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M0.999985 466.999C0.999977 658.089 209.635 812.999 467 812.999C724.365 812.999 933 658.09 933 466.999C933 275.909 724.365 120.999 467 120.999C209.635 120.999 0.999993 275.908 0.999985 466.999Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M0.999987 466.999C0.999981 624.952 209.635 752.999 467 752.999C724.365 752.999 933 624.952 933 466.999C933 309.046 724.365 180.999 467 180.999C209.635 180.999 0.999994 309.046 0.999987 466.999Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M0.99999 466.999C0.999985 591.815 209.635 692.999 467 692.999C724.365 692.999 933 591.815 933 466.999C933 342.183 724.365 240.999 467 240.999C209.635 240.999 0.999996 342.183 0.99999 466.999Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M0.999993 466.999C0.999989 558.678 209.635 632.999 467 632.999C724.365 632.999 933 558.678 933 466.999C933 375.32 724.365 300.999 467 300.999C209.635 300.999 0.999997 375.32 0.999993 466.999Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M0.999995 466.999C0.999993 525.541 209.635 572.999 467 572.999C724.365 572.999 933 525.541 933 466.999C933 408.457 724.365 360.999 467 360.999C209.635 360.999 0.999998 408.457 0.999995 466.999Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M0.999998 469.999C0.999997 495.404 209.635 515.999 467 515.999C724.365 515.999 933 495.404 933 469.999C933 444.594 724.365 423.999 467 423.999C209.635 423.999 0.999999 444.594 0.999998 469.999Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M933 469.999L1 469.999",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M467 933C724.365 933 933 724.365 933 467C933 209.635 724.365 1 467 1C209.635 1 1 209.635 1 467C1 724.365 209.635 933 467 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }))), /* @__PURE__ */ h("img", {
    className: "absolute top-[7%] left-0 w-[30.13%]",
    src: "/static/doing-it-1.jpg",
    alt: "Employee with laptop"
  }), /* @__PURE__ */ h("img", {
    className: "absolute top-[-11%] left-[25%] w-[17.71%]",
    src: "/static/doing-it-2.jpg",
    alt: "Employee on alotment"
  }), /* @__PURE__ */ h("img", {
    className: "absolute top-[11%] right-0 w-[30.37%]",
    src: "/static/doing-it-3.jpg",
    alt: "Employees with food package"
  }), /* @__PURE__ */ h("img", {
    className: "absolute bottom-[-5%] right-[37%] w-[30.37%]",
    src: "/static/doing-it-4.jpg",
    alt: "Employees out walking"
  }), /* @__PURE__ */ h("img", {
    className: "absolute bottom-[8%] right-[12%] w-[17.71%]",
    src: "/static/doing-it-5.jpg",
    alt: "Bus stop billboard"
  })), /* @__PURE__ */ h("div", {
    className: "relative border-t border-grey-light mt-24 md:mt-42 lg:mt-44 xl:mt-80 pt-14 md:pt-24 xl:pt-36"
  }, /* @__PURE__ */ h("h3", {
    className: "mb-10 text-h3"
  }, "Next chapter"), /* @__PURE__ */ h("h2", {
    className: "text-xxl"
  }, "A bold", /* @__PURE__ */ h("br", null), "future"), /* @__PURE__ */ h("img", {
    className: "block ml-auto mr-10 mt-12 md:mt-20 lg:mt-12 xl:mt-0 xl:absolute xl:top-56 xl:right-20 2xl:right-56 w-[200px] md:w-[484px] lg:w-[525px] xl:w-[657px] pointer-events-none select-none",
    src: "/static/mountains.png",
    alt: "Mountains with moon and stars"
  }), /* @__PURE__ */ h(Link, {
    className: "absolute bottom-10 md:bottom-32 lg:bottom-28 xl:bottom-0 left-0 xl:left-auto xl:right-0 2xl:right-20 transition-opacity hover:opacity-50",
    to: "/a-bold-future/"
  }, /* @__PURE__ */ h("span", {
    className: "sr-only"
  }, "Next"), /* @__PURE__ */ h("svg", {
    width: "73",
    height: "44",
    viewBox: "0 0 73 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    d: "M59 1C66.7459 5.04411 72 12.929 72 22C72 31.071 66.7459 38.9559 59 43",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M14 43C6.25407 38.9559 1 31.071 1 22C1 12.929 6.25407 5.04411 14 0.999998",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M47.2 12C47.2 16.5607 51.4062 20.5714 57 20.5714V23.4286C51.4062 23.4286 47.2 27.4394 47.2 32H44.4C44.4 28.5263 46.0707 25.4948 48.6638 23.4286L15 23.4286V20.5714L48.6638 20.5714C46.0707 18.5052 44.4 15.4737 44.4 12H47.2Z",
    fill: "white"
  }))))));
}
