import {Fragment, h} from "../_snowpack/pkg/preact.js";
import {Link} from "../_snowpack/pkg/react-router-dom.js";
export default function WhatIsBcorp() {
  return /* @__PURE__ */ h(Fragment, null, /* @__PURE__ */ h("div", {
    className: "container mt-24 lg:mt-36 xl:mt-72"
  }, /* @__PURE__ */ h("h1", {
    className: "text-xxl"
  }, "What is", /* @__PURE__ */ h("br", null), "B Corp?"), /* @__PURE__ */ h("div", {
    className: "relative z-10 xl:grid xl:grid-cols-12 mt-8 md:mt-12 lg:mt-14 xl:mt-28"
  }, /* @__PURE__ */ h("div", {
    className: "xl:col-span-3 2xl:col-span-5 pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "block w-[66px] md:w-[95px]",
    src: "/static/menu-what-is-b-corp.png",
    alt: "B Corp logo"
  })), /* @__PURE__ */ h("div", {
    className: "xl:col-span-9 2xl:col-span-7 mt-10 md:mt-14 xl:mt-0 border-t border-grey-light"
  }, /* @__PURE__ */ h("div", {
    className: "grid grid-cols-12 gap-x-5 md:gap-x-10"
  }, /* @__PURE__ */ h("div", {
    className: "col-span-5 md:col-span-4 lg:col-span-6 pt-4"
  }, /* @__PURE__ */ h("h2", {
    className: "text-h3"
  }, "An overview")), /* @__PURE__ */ h("div", {
    className: "col-span-7 md:col-span-8 lg:col-span-6 pt-5"
  }, /* @__PURE__ */ h("p", null, "‘Certified B Corporations are businesses that meet the highest standards of verified social and environmental performance, public transparency, and legal accountability to balance profit and purpose."), /* @__PURE__ */ h("p", {
    className: "mt-5"
  }, "Certification is a time consuming process over a number of months that assesses the impact on your workers, customers, community and environment."), /* @__PURE__ */ h("p", {
    className: "mt-12 xl:mt-20"
  }, /* @__PURE__ */ h("a", {
    className: "relative inline-block uppercase text-sm pr-20 md:pr-24 transition-opacity hover:opacity-50",
    href: "https://bcorporation.net/about-b-corps"
  }, "Find out more", /* @__PURE__ */ h("svg", {
    className: "absolute top-1/2 right-0 w-[60px] md:w-[73px] transform -translate-y-1/2",
    viewBox: "0 0 73 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    d: "M59 1C66.7459 5.04411 72 12.929 72 22C72 31.071 66.7459 38.9559 59 43",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M14 43C6.25407 38.9559 1 31.071 1 22C1 12.929 6.25407 5.04411 14 0.999998",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M47.2 12C47.2 16.5607 51.4062 20.5714 57 20.5714V23.4286C51.4062 23.4286 47.2 27.4394 47.2 32H44.4C44.4 28.5263 46.0707 25.4948 48.6638 23.4286L15 23.4286V20.5714L48.6638 20.5714C46.0707 18.5052 44.4 15.4737 44.4 12H47.2Z",
    fill: "white"
  })))))))), /* @__PURE__ */ h("div", {
    className: "relative -z-1 -mx-3 md:mx-0 mt-10 lg:-mt-24 xl:-mt-64 select-none"
  }, /* @__PURE__ */ h("svg", {
    className: "relative block mx-auto max-w-full",
    width: "1092",
    viewBox: "0 0 1092 1092",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("g", {
    opacity: "0.3"
  }, /* @__PURE__ */ h("path", {
    d: "M542.492 1091C804.732 1091 1017.32 846.995 1017.32 546C1017.32 245.005 804.732 1 542.492 1C280.251 1 67.6636 245.005 67.6636 546C67.6636 846.995 280.251 1091 542.492 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M542.491 1091C765.977 1091 947.148 846.995 947.148 546C947.148 245.005 765.977 1 542.491 1C319.005 1 137.834 245.005 137.834 546C137.834 846.995 319.005 1091 542.491 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M542.491 1091C727.222 1091 876.976 846.995 876.976 546C876.976 245.005 727.222 1 542.491 1C357.76 1 208.006 245.005 208.006 546C208.006 846.995 357.76 1091 542.491 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M542.492 1091C688.468 1091 806.805 846.995 806.805 546C806.805 245.005 688.468 1 542.492 1C396.516 1 278.179 245.005 278.179 546C278.179 846.995 396.516 1091 542.492 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M542.491 1091C649.713 1091 736.633 846.995 736.633 546C736.633 245.005 649.713 1 542.491 1C435.27 1 348.35 245.005 348.35 546C348.35 846.995 435.27 1091 542.491 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M542.491 1091C610.958 1091 666.461 846.995 666.461 546C666.461 245.005 610.958 1 542.491 1C474.025 1 418.521 245.005 418.521 546C418.521 846.995 474.025 1091 542.491 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M542.492 1091C572.204 1091 596.29 846.995 596.29 546C596.29 245.005 572.204 1 542.492 1C512.78 1 488.693 245.005 488.693 546C488.693 846.995 512.78 1091 542.492 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M546 1V1091",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M546 1091C846.995 1091 1091 846.995 1091 546C1091 245.005 846.995 1 546 1C245.005 1 1 245.005 1 546C1 846.995 245.005 1091 546 1091Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }))), /* @__PURE__ */ h("div", {
    className: "absolute top-1/2 w-full transform -translate-y-1/2 left-0 text-h3 text-center"
  }, /* @__PURE__ */ h("div", {
    className: "text-h3 pb-8 xl:pb-0 xl:float-left xl:mr-5"
  }, "How we score"), /* @__PURE__ */ h("div", {
    className: "text-h3 pt-8 xl:pt-0 border-t border-grey-light xl:border-0 xl:float-right xl:ml-5"
  }, "Against industry average"), /* @__PURE__ */ h("div", {
    className: "hidden xl:block border-t border-grey-light mt-4 overflow-hidden"
  }))), /* @__PURE__ */ h("div", {
    className: "relative z-10 mt-10 xl:-mt-52 pb-28"
  }, /* @__PURE__ */ h("table", {
    className: "w-full 2xl:w-10/12 mb-5"
  }, /* @__PURE__ */ h("thead", null, /* @__PURE__ */ h("tr", null, /* @__PURE__ */ h("th", {
    className: "align-bottom text-left font-normal pb-5 pr-5 md:pr-10"
  }, "Area of impact"), /* @__PURE__ */ h("th", {
    className: "align-bottom text-left font-normal pb-5 pr-5 md:pr-10"
  }, "Yoyo score"), /* @__PURE__ */ h("th", {
    className: "align-bottom text-left font-normal pb-5"
  }, "UK industry average"))), /* @__PURE__ */ h("tbody", null, /* @__PURE__ */ h("tr", null, /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 md:text-xl xl:text-h2 py-3 pr-5 md:pr-10 align-bottom"
  }, "Governance"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 pr-5 md:pr-10 align-bottom"
  }, "18.4"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 align-bottom"
  }, "15.4")), /* @__PURE__ */ h("tr", null, /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 md:text-xl xl:text-h2 py-3 pr-5 md:pr-10 align-bottom"
  }, "Workers"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 pr-5 md:pr-10 align-bottom"
  }, "37.7"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 align-bottom"
  }, "27.1")), /* @__PURE__ */ h("tr", null, /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 md:text-xl xl:text-h2 py-3 pr-5 md:pr-10 align-bottom"
  }, "Community"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 pr-5 md:pr-10 align-bottom"
  }, "21.6"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 align-bottom"
  }, "25.1")), /* @__PURE__ */ h("tr", null, /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 md:text-xl xl:text-h2 py-3 pr-5 md:pr-10 align-bottom"
  }, "Environment"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 pr-5 md:pr-10 align-bottom"
  }, "9.6"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 align-bottom"
  }, "15.9")), /* @__PURE__ */ h("tr", null, /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 md:text-xl xl:text-h2 py-3 pr-5 md:pr-10 align-bottom"
  }, "Customers"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 pr-5 md:pr-10 align-bottom"
  }, "12.5"), /* @__PURE__ */ h("td", {
    className: "border-t border-grey-light text-h3 py-3 xl:py-5 align-bottom"
  }, "8.2")), /* @__PURE__ */ h("tr", null, /* @__PURE__ */ h("td", {
    className: "text-h1 pt-16 xl:pt-40 pr-5 align-bottom"
  }, "Total:"), /* @__PURE__ */ h("td", {
    className: "text-h1 md:text-xl xl:text-h2 pt-16 xl:pt-40 pr-5 align-bottom"
  }, "100"), /* @__PURE__ */ h("td", {
    className: "text-h1 md:text-xl xl:text-h2 pt-16 xl:pt-40 align-bottom"
  }, "92")))), /* @__PURE__ */ h("p", null, "*A minimum score of 80 is required to qualify for certification")), /* @__PURE__ */ h("div", {
    className: "grid grid-cols-12 gap-2 xl:gap-10 2xl:w-11/12 mt-16 md:mt-24 xl:mt-72 border-t border-grey-light"
  }, /* @__PURE__ */ h("div", {
    className: "col-span-5 xl:col-span-6 pt-4"
  }, /* @__PURE__ */ h("h3", {
    className: "text-h3"
  }, "Looking to the future")), /* @__PURE__ */ h("div", {
    className: "col-span-7 xl:col-span-6"
  }, /* @__PURE__ */ h("p", {
    className: "mt-5"
  }, "We’re incredibly humbled to start our B Corp journey with an above average score, particularly as a relatively small company applying for the first-time. Looking towards the future, we see plenty of exciting opportunities for improvement to increase our impact and continue to be worthy of our certification."))), /* @__PURE__ */ h("img", {
    className: "block mt-5 md:mt-0 xl:mt-12 w-[97px] md:w-[199px] lg:w-[243px] xl:w-[320px] select-none",
    src: "/static/eye.png",
    alt: "Open eye"
  }), /* @__PURE__ */ h("div", {
    className: "lg:w-10/12 xl:w-full 2xl:w-10/12 mt-20 md:mt-44 lg:mt-48 xl:mt-52 2xl:mt-72 ml-auto"
  }, /* @__PURE__ */ h("h4", {
    className: "mb-5"
  }, "What does being a B Corp mean for us?"), /* @__PURE__ */ h("ul", null, /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Look after our people"), /* @__PURE__ */ h("p", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, "The people are the most important part of the business. And the business needs to work for and look after all of us.")), /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Invest in our community"), /* @__PURE__ */ h("p", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, "This is where we call home. We need to keep nurturing, learning from and investing in it.")), /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Go further with our clients"), /* @__PURE__ */ h("p", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, "The work we deliver to our clients has meaning and we need to continue to offer this value to the world.")), /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Empower our suppliers"), /* @__PURE__ */ h("p", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, "We want our suppliers to subscribe to the same values as us, so we continue to empower them to do so.")), /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Take care of our environment"), /* @__PURE__ */ h("p", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, "We want to minimise any negative impact we have on our world and so we implement activities that reverse some of the damage we cause.")), /* @__PURE__ */ h("li", {
    className: "md:grid md:grid-cols-12 xl:grid-cols-10 md:gap-14 border-t border-grey-light"
  }, /* @__PURE__ */ h("h5", {
    className: "md:col-span-7 xl:col-span-7 pt-6 xl:py-3 text-h2 md:text-xl xl:text-h2"
  }, "Honour our legal requirement"), /* @__PURE__ */ h("p", {
    className: "ml-auto w-8/12 md:w-auto md:col-span-5 xl:col-span-3 py-10 xl:py-5"
  }, "We have made a legally binding commitment to manage the business using the triple bottom line: Profit, People and the Planet."))))), /* @__PURE__ */ h("div", {
    className: "mt-24 md:mt-36 xl:mt-72 pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "block w-full",
    src: "/static/what-is-bcorp.jpg",
    alt: "Forest"
  })), /* @__PURE__ */ h("div", {
    className: "container"
  }, /* @__PURE__ */ h("div", {
    className: "mt-24 md:mt-36 lg:mt-56 xl:mt-72"
  }, /* @__PURE__ */ h("h4", {
    className: "mb-4 md:mb-14"
  }, "What are the benefits for our clients?"), /* @__PURE__ */ h("p", {
    className: "text-xl xl:w-10/12"
  }, "A core part of the process to becoming B Corp certified is about understanding the value we offer to everyone who comes into contact with us. This seal of approval means we’re an authentic business that behaves with the right intentions.")), /* @__PURE__ */ h("div", {
    className: "mt-20 md:mt-36 lg:mt-64 xl:mt-72 lg:grid lg:grid-cols-12 lg:gap-x-20 xl:gap-x-24 2xl:gap-x-32"
  }, /* @__PURE__ */ h("div", {
    className: "lg:col-span-5 xl:col-span-4 border-t border-grey-light pt-4"
  }, /* @__PURE__ */ h("h3", {
    className: "text-h3"
  }, "Julia Beran, Co-Founder & Managing Director, Vintner")), /* @__PURE__ */ h("div", {
    className: "lg:col-span-7 xl:col-span-8 lg:-mt-2"
  }, /* @__PURE__ */ h("p", {
    className: "mt-14 lg:mt-0 text-xl xl:text-h2"
  }, "'Finding the right agency to partner with can be a daunting task and takes careful consideration.'"), /* @__PURE__ */ h("div", {
    className: "md:w-2/3 lg:w-auto xl:w-7/12"
  }, /* @__PURE__ */ h("p", {
    className: "mt-10 md:mt-14 xl:mt-24"
  }, "'Knowing that Yoyo has achieved B Corp Accreditation is a demonstration of their commitment to the highest standards and provides us with a clear picture of their values and priorities. It is reassuring to understand exactly the type of business we are working with, offering full transparency, an ethical supply chain and a commitment to sustainability."), /* @__PURE__ */ h("p", {
    className: "mt-5"
  }, "It is clear that Yoyo look after their people and support their community and this is the sort of business that Vintner will align ourselves with and trust with our investment.'")), /* @__PURE__ */ h("img", {
    className: "block w-[83px] md:w-[110px] xl:w-[155px] 2xl:w-[212px] ml-auto mt-5 md:-mt-28 lg:mt-20 xl:mt-0 2xl:-mt-24 xl:mr-20 select-none",
    src: "/static/bottle.png",
    alt: "Champagne bottle"
  }))), /* @__PURE__ */ h("div", {
    className: "mt-20 md:mt-24 lg:mt-6 xl:mt-32 lg:w-9/12 xl:w-11/12 2xl:w-8/12"
  }, /* @__PURE__ */ h("h3", {
    className: "text-xl xl:text-h2"
  }, "What are the benefits", /* @__PURE__ */ h("br", null), "for our people?"), /* @__PURE__ */ h("ul", {
    className: "mt-10 md:mt-12 xl:mt-20"
  }, /* @__PURE__ */ h("li", {
    className: "py-4 border-t border-grey-light"
  }, "A sense of purpose"), /* @__PURE__ */ h("li", {
    className: "py-4 border-t border-grey-light"
  }, "Being part of something bigger than the team, bigger than the company"), /* @__PURE__ */ h("li", {
    className: "py-4 border-t border-grey-light"
  }, "Working for and alongside people who share the same values"), /* @__PURE__ */ h("li", {
    className: "py-4 border-t border-grey-light"
  }, "A sense of constantly challenging ourselves, collectively and individually, to be better"), /* @__PURE__ */ h("li", {
    className: "py-4 border-t border-grey-light"
  }, "A sense of pride to ‘wear the B Corp badge’"), /* @__PURE__ */ h("li", {
    className: "py-4 border-t border-grey-light"
  }, "Regular opportunities to support the local community"), /* @__PURE__ */ h("li", {
    className: "py-4 border-t border-grey-light"
  }, "Knowledge that everything we do, buy, use, consume has the good of the environment in mind"))), /* @__PURE__ */ h("div", {
    className: "mt-24 md:mt-36 xl:mt-72 overflow-hidden pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "block float-left mt-[12%] w-[44.44%]",
    src: "/static/what-is-bcorp-1.jpg",
    alt: "Emmployee"
  }), /* @__PURE__ */ h("img", {
    className: "block float-right w-[44.44%]",
    src: "/static/what-is-bcorp-2.jpg",
    alt: "Employees"
  })), /* @__PURE__ */ h("div", {
    className: "xl:w-11/12 2xl:w-full mt-24 md:mt-36 xl:mt-72 2xl:grid 2xl:grid-cols-10 lg:gap-x-20"
  }, /* @__PURE__ */ h("div", {
    className: "2xl:col-span-3 pt-4 border-t border-top-grey-light 2xl:mt-2"
  }, /* @__PURE__ */ h("h3", {
    className: "text-h3"
  }, "Employee testimonials")), /* @__PURE__ */ h("div", {
    className: "2xl:col-span-7"
  }, /* @__PURE__ */ h("div", {
    className: "lg:grid lg:grid-cols-2 lg:gap-x-20"
  }, /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 mt-16 md:mt-24 2xl:mt-0"
  }, /* @__PURE__ */ h("blockquote", null, /* @__PURE__ */ h("p", {
    className: "text-xl"
  }, "'Yoyo really cares for its people and community.'"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-8 lg:mt-10"
  }, "'It’s something that has always been there, but becoming a B Corp has given this real structure and focus. It pushes us to always do better. The result is that I’m proud to be part of a small company with a great sense of purpose. I enjoy the challenge of ensuring we’re profitable and well run, alongside exciting and meaningful initiatives to play an active role in our community and do our bit for the planet.'"), /* @__PURE__ */ h("cite", {
    className: "block mt-14 md:mt-16 xl:mt-24 not-italic"
  }, "Matt Lee, Head of Strategy & Experience"))), /* @__PURE__ */ h("div", {
    className: "lg:col-span-1 mt-16 md:mt-24 2xl:mt-0"
  }, /* @__PURE__ */ h("blockquote", null, /* @__PURE__ */ h("p", {
    className: "text-xl"
  }, "'I couldn’t have ended up working for a better agency.'"), /* @__PURE__ */ h("p", {
    className: "mt-5 md:mt-8 lg:mt-10"
  }, "'Yoyo and the senior leadership team truly care about everyone who works for them. They give us the tools and opportunities to grow. They listen to what we need, the struggles we are facing, and offer solutions and support. They have enlightened us all about what it means to be a B Corp and how we can use our autonomy to support change. For people, planet and profit.'"), /* @__PURE__ */ h("cite", {
    className: "block mt-14 md:mt-16 xl:mt-24 not-italic"
  }, "Megan Deefholts, Partnership Executive")))))), /* @__PURE__ */ h("div", {
    className: "relative border-t border-grey-light mt-24 md:mt-36 lg:mt-48 xl:mt-72 pt-14 md:pt-24 xl:pt-36"
  }, /* @__PURE__ */ h("h3", {
    className: "mb-10 text-h3"
  }, "Next chapter"), /* @__PURE__ */ h("h2", {
    className: "text-xxl"
  }, "Certified!", /* @__PURE__ */ h("br", null), "Year one"), /* @__PURE__ */ h("img", {
    className: "block ml-auto mr-10 mt-12 md:mt-20 lg:mt-12 xl:mt-0 xl:absolute xl:top-56 xl:right-20 2xl:right-56 w-[169px] md:w-[358px] pointer-events-none select-none",
    src: "/static/candle.png",
    alt: "Candle"
  }), /* @__PURE__ */ h(Link, {
    className: "absolute bottom-16 md:bottom-32 lg:bottom-28 xl:bottom-0 left-0 xl:left-auto xl:right-0 2xl:right-20 transition-opacity hover:opacity-50",
    to: "/certified/"
  }, /* @__PURE__ */ h("span", {
    className: "sr-only"
  }, "Next"), /* @__PURE__ */ h("svg", {
    width: "73",
    height: "44",
    viewBox: "0 0 73 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    d: "M59 1C66.7459 5.04411 72 12.929 72 22C72 31.071 66.7459 38.9559 59 43",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M14 43C6.25407 38.9559 1 31.071 1 22C1 12.929 6.25407 5.04411 14 0.999998",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M47.2 12C47.2 16.5607 51.4062 20.5714 57 20.5714V23.4286C51.4062 23.4286 47.2 27.4394 47.2 32H44.4C44.4 28.5263 46.0707 25.4948 48.6638 23.4286L15 23.4286V20.5714L48.6638 20.5714C46.0707 18.5052 44.4 15.4737 44.4 12H47.2Z",
    fill: "white"
  }))))));
}
