import {h} from "../_snowpack/pkg/preact.js";
export default function Footer() {
  return /* @__PURE__ */ h("footer", {
    className: "container pt-20 md:pt-36 pb-4 md:pb-12 lg:pb-16 xl:pb-20 2xl:pb-24"
  }, /* @__PURE__ */ h("div", {
    className: "md:grid md:grid-cols-12"
  }, /* @__PURE__ */ h("div", {
    className: "md:col-span-6 xl:col-span-4 self-end text-lg"
  }, /* @__PURE__ */ h("p", {
    className: "mb-7"
  }, "Yoyo Design", /* @__PURE__ */ h("br", null), "90 Calverley Road,", /* @__PURE__ */ h("br", null), "Tunbridge Wells, Kent", /* @__PURE__ */ h("br", null), "TN1 2UN"), /* @__PURE__ */ h("p", null, "e:", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "mailto:info@yoyodesign.com"
  }, "info@yoyodesign.com"), /* @__PURE__ */ h("br", null), "t:", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "tel:01892619555"
  }, "+44 (0) 1892 619 555"), /* @__PURE__ */ h("br", null), "w:", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.yoyodesign.com"
  }, "yoyodesign.com"))), /* @__PURE__ */ h("div", {
    className: "md:col-span-6 xl:col-span-8 md:self-end"
  }, /* @__PURE__ */ h("div", {
    className: "xl:grid xl:grid-cols-2 xl:gap-x-10"
  }, /* @__PURE__ */ h("div", {
    className: "xl:col-span-1 mt-10"
  }, /* @__PURE__ */ h("ul", {
    className: "flex flex-wrap md:justify-end -ml-8 md:-ml-16"
  }, /* @__PURE__ */ h("li", {
    className: "mt-4 md:mt-0 pl-8 md:pl-16"
  }, /* @__PURE__ */ h("a", {
    className: "block text-md underline transition-opacity hover:opacity-50",
    href: "https://yoyodesign.com/careers/"
  }, "Careers")), /* @__PURE__ */ h("li", {
    className: "mt-4 md:mt-0 pl-8 md:pl-16"
  }, /* @__PURE__ */ h("a", {
    className: "block text-md underline transition-opacity hover:opacity-50",
    href: "https://uk.linkedin.com/company/yoyo-design-ltd"
  }, "LinkedIn")), /* @__PURE__ */ h("li", {
    className: "mt-4 md:mt-0 pl-8 md:pl-16"
  }, /* @__PURE__ */ h("a", {
    className: "block text-md underline transition-opacity hover:opacity-50",
    href: "https://www.instagram.com/yoyodesign_/"
  }, "Instagram")))), /* @__PURE__ */ h("div", {
    className: "xl:col-span-1 mt-10 text-md md:text-right"
  }, "© 2021 Yoyo Design Ltd. All rights reserved.")))));
}
