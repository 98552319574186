import {h} from "../_snowpack/pkg/preact.js";
import {useState, useEffect} from "../_snowpack/pkg/preact/hooks.js";
import {NavLink, useLocation} from "../_snowpack/pkg/react-router-dom.js";
export default function Menu() {
  const [menuOpen, setMenuOpen] = useState(false);
  let location = useLocation();
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenuOpen(false);
    window.ga && window.ga("send", "pageview", {
      page: window.location.pathname
    });
  }, [location]);
  return /* @__PURE__ */ h("nav", {
    className: "fixed z-40 top-0 left-0 w-full xl:w-[72px] xl:min-h-full select-none bg-grey-dark"
  }, /* @__PURE__ */ h(NavLink, {
    exact: true,
    to: "/",
    className: "relative z-50 inline-block align-bottom px-3 py-3.5 md:px-5 md:py-5 transition-opacity hover:opacity-50"
  }, /* @__PURE__ */ h("span", {
    className: "sr-only"
  }, "Home"), /* @__PURE__ */ h("svg", {
    className: "w-7 xl:w-8",
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("mask", {
    id: "mask0",
    "mask-type": "alpha",
    maskUnits: "userSpaceOnUse",
    x: "0",
    y: "0",
    width: "32",
    height: "32"
  }, /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M0 0.296387H31.4913V31.7874H0V0.296387Z",
    fill: "white"
  })), /* @__PURE__ */ h("g", {
    mask: "url(#mask0)"
  }, /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M31.4916 16.0531C31.4916 11.7037 29.9662 7.99232 26.8921 4.91855C23.8184 1.84447 20.0839 0.296326 15.7348 0.296326C11.4086 0.296326 7.69689 1.84447 4.6003 4.91855C1.52593 7.99232 0 11.7037 0 16.0531C0 20.3793 1.52593 24.091 4.6003 27.1876C7.69689 30.2388 11.4086 31.7876 15.7348 31.7876C20.0839 31.7876 23.8184 30.2388 26.8921 27.1876C29.9662 24.091 31.4916 20.3793 31.4916 16.0531ZM24.8198 16.0531C24.8198 18.5576 23.9321 20.6756 22.1555 22.4519C20.4023 24.2051 18.2624 25.0931 15.7347 25.0931C13.2527 25.0931 11.1119 24.2051 9.33591 22.4519C7.55991 20.6756 6.67191 18.5576 6.67191 16.0531C6.67191 13.5485 7.55991 11.408 9.33591 9.63172C11.1119 7.85572 13.2527 6.96772 15.7347 6.96772C18.2624 6.96772 20.4023 7.85572 22.1555 9.63172C23.9321 11.408 24.8198 13.5485 24.8198 16.0531Z",
    fill: "white"
  })), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M21.4045 12.1363C21.4045 11.4992 21.1767 10.9526 20.7441 10.4972C20.2659 10.0421 19.7195 9.81421 19.0591 9.81421C18.3986 9.81421 17.8753 10.0421 17.4199 10.4972C16.9645 10.9526 16.7139 11.4992 16.7139 12.1363C16.7139 12.7739 16.9645 13.3431 17.4199 13.7985C17.8753 14.2539 18.3986 14.4821 19.0591 14.4821C19.7195 14.4821 20.2659 14.2539 20.7441 13.7985C21.1767 13.3431 21.4045 12.7739 21.4045 12.1363Z",
    fill: "white"
  }))), /* @__PURE__ */ h("button", {
    className: "absolute z-20 top-1/2 right-0 w-[52px] h-14 md:w-[68px] md:h-[68px] xl:right-auto xl:left-0 xl:w-full transform -translate-y-1/2 transition-opacity hover:opacity-50 focus:outline-none",
    type: "button",
    onClick: toggleMenu
  }, /* @__PURE__ */ h("span", {
    className: "sr-only"
  }, "Menu"), /* @__PURE__ */ h("span", {
    className: `absolute top-1/2 left-1/2 w-6 h-0.5 transform -translate-x-1/2 -translate-y-1/2 bg-white transition-all origin-center ${menuOpen ? "rotate-45 mt-0" : "rotate-0 mt-[-5px]"}`
  }), /* @__PURE__ */ h("span", {
    className: `absolute top-1/2 left-1/2 w-6 h-0.5 transform -translate-x-1/2 -translate-y-1/2 bg-white transition-all origin-center ${menuOpen ? "-rotate-45 mt-0" : "-rotate-0 mt-[5px]"}`
  })), /* @__PURE__ */ h("div", {
    className: `fixed z-10 top-0 border-t-[56px] md:border-t-[68px] xl:border-t-0 border-transparent left-0 xl:pl-[72px] w-full h-screen py-0 overflow-y-auto overscroll-y-contain scrollbar-none flex transform-gpu transition-transform/opacity duration-700 ${menuOpen ? "translate-x-0 opacity-100" : "translate-x-full xl:-translate-x-full opacity-0"}`
  }, /* @__PURE__ */ h("div", {
    className: "self-center container max-h-full pt-5 pb-44 md:pb-5"
  }, /* @__PURE__ */ h("ol", {
    className: "w-full lg:max-w-3xl xl:max-w-[1067px]"
  }, /* @__PURE__ */ h("li", {
    className: "group block md:pl-14"
  }, /* @__PURE__ */ h(NavLink, {
    exact: true,
    activeClassName: "opacity-active",
    to: "/",
    className: "group relative block xl:grid xl:grid-cols-4 xl:gap-x-5 py-5 md:pb-10 border-t border-grey-light items-start opacity-20 transition-opacity hover:opacity-100"
  }, /* @__PURE__ */ h("span", {
    className: "block mb-1 md:absolute md:top-5 md:right-full md:pr-5 text-menu-number"
  }, "01"), /* @__PURE__ */ h("span", {
    className: "xl:col-span-3 block text-menu-title tracking-tight"
  }, "Soul searching"), /* @__PURE__ */ h("span", {
    className: "hidden md:block xl:col-span-1 mt-5 xl:mt-0 text-menu-summary"
  }, "A foreword by Jenny Kitchen, CEO, us & our industry"), /* @__PURE__ */ h("svg", {
    className: "hidden lg:block absolute top-0 left-full mt-5 ml-10 2xl:ml-20 opacity-0 transition-all pointer-events-none group-hover:ml-20 2xl:group-hover:ml-40 group-hover:opacity-100",
    width: "73",
    height: "44",
    viewBox: "0 0 73 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    d: "M59 1C66.7459 5.04411 72 12.929 72 22C72 31.071 66.7459 38.9559 59 43",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M14 43C6.25407 38.9559 1 31.071 1 22C1 12.929 6.25407 5.04411 14 0.999998",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M47.2 12C47.2 16.5607 51.4062 20.5714 57 20.5714V23.4286C51.4062 23.4286 47.2 27.4394 47.2 32H44.4C44.4 28.5263 46.0707 25.4948 48.6638 23.4286L15 23.4286V20.5714L48.6638 20.5714C46.0707 18.5052 44.4 15.4737 44.4 12H47.2Z",
    fill: "white"
  }))), /* @__PURE__ */ h("img", {
    className: "absolute bottom-10 right-10 w-[172px] 2xl:w-[241px] opacity-0 transition-all pointer-events-none menu-sm:group-hover:opacity-100 menu-md:group-hover:opacity-100 menu-lg:group-hover:opacity-100 menu-xl:group-hover:opacity-100 menu-2xl:group-hover:opacity-100",
    src: "/static/crystal-ball.png",
    alt: "Crystal Ball"
  })), /* @__PURE__ */ h("li", {
    className: "group block md:pl-14"
  }, /* @__PURE__ */ h(NavLink, {
    activeClassName: "opacity-active",
    to: "/what-is-b-corp/",
    className: "group relative block xl:grid xl:grid-cols-4 xl:gap-x-5 py-5 md:pb-10 border-t border-grey-light items-start opacity-20 transition-opacity hover:opacity-100"
  }, /* @__PURE__ */ h("span", {
    className: "block mb-1 md:absolute md:top-5 md:right-full md:pr-5 text-menu-number"
  }, "02"), /* @__PURE__ */ h("span", {
    className: "col-span-3 block text-menu-title tracking-tight"
  }, "What is B Corp"), /* @__PURE__ */ h("span", {
    className: "hidden md:block xl:col-span-1 mt-5 xl:mt-0 text-menu-summary"
  }, "What is means for us, our clients and our team"), /* @__PURE__ */ h("svg", {
    className: "hidden lg:block absolute top-0 left-full mt-5 ml-10 2xl:ml-20 opacity-0 transition-all pointer-events-none group-hover:ml-20 2xl:group-hover:ml-40 group-hover:opacity-100",
    width: "73",
    height: "44",
    viewBox: "0 0 73 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    d: "M59 1C66.7459 5.04411 72 12.929 72 22C72 31.071 66.7459 38.9559 59 43",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M14 43C6.25407 38.9559 1 31.071 1 22C1 12.929 6.25407 5.04411 14 0.999998",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M47.2 12C47.2 16.5607 51.4062 20.5714 57 20.5714V23.4286C51.4062 23.4286 47.2 27.4394 47.2 32H44.4C44.4 28.5263 46.0707 25.4948 48.6638 23.4286L15 23.4286V20.5714L48.6638 20.5714C46.0707 18.5052 44.4 15.4737 44.4 12H47.2Z",
    fill: "white"
  }))), /* @__PURE__ */ h("img", {
    className: "absolute bottom-10 right-20 2xl:right-24 opacity-0 w-[106px] 2xl:w-[130px] transition-all pointer-events-none menu-sm:group-hover:opacity-100 menu-md:group-hover:opacity-100 menu-lg:group-hover:opacity-100 menu-xl:group-hover:opacity-100 menu-2xl:group-hover:opacity-100",
    src: "/static/menu-what-is-b-corp.png",
    alt: "B Corp logo"
  })), /* @__PURE__ */ h("li", {
    className: "group block md:pl-14"
  }, /* @__PURE__ */ h(NavLink, {
    activeClassName: "opacity-active",
    to: "/certified/",
    className: "group relative block xl:grid xl:grid-cols-4 xl:gap-x-5 py-5 md:pb-10 border-t border-grey-light items-start opacity-20 transition-opacity hover:opacity-100"
  }, /* @__PURE__ */ h("span", {
    className: "block mb-1 md:absolute md:top-5 md:right-full md:pr-5 text-menu-number"
  }, "03"), /* @__PURE__ */ h("span", {
    className: "col-span-3 block text-menu-title tracking-tight"
  }, "Certified!"), /* @__PURE__ */ h("span", {
    className: "hidden md:block xl:col-span-1 mt-5 xl:mt-0 text-menu-summary"
  }, "How we’ve lived our first year as a B Corporation"), /* @__PURE__ */ h("svg", {
    className: "hidden lg:block absolute top-0 left-full mt-5 ml-10 2xl:ml-20 opacity-0 transition-all pointer-events-none group-hover:ml-20 2xl:group-hover:ml-40 group-hover:opacity-100",
    width: "73",
    height: "44",
    viewBox: "0 0 73 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    d: "M59 1C66.7459 5.04411 72 12.929 72 22C72 31.071 66.7459 38.9559 59 43",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M14 43C6.25407 38.9559 1 31.071 1 22C1 12.929 6.25407 5.04411 14 0.999998",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M47.2 12C47.2 16.5607 51.4062 20.5714 57 20.5714V23.4286C51.4062 23.4286 47.2 27.4394 47.2 32H44.4C44.4 28.5263 46.0707 25.4948 48.6638 23.4286L15 23.4286V20.5714L48.6638 20.5714C46.0707 18.5052 44.4 15.4737 44.4 12H47.2Z",
    fill: "white"
  }))), /* @__PURE__ */ h("img", {
    className: "absolute bottom-10 right-10 w-[190px] 2xl:w-[305px] opacity-0 transition-all pointer-events-none menu-sm:group-hover:opacity-100 menu-md:group-hover:opacity-100 menu-lg:group-hover:opacity-100 menu-xl:group-hover:opacity-100 menu-2xl:group-hover:opacity-100",
    src: "/static/candle.png",
    alt: "Candle"
  })), /* @__PURE__ */ h("li", {
    className: "group block md:pl-14"
  }, /* @__PURE__ */ h(NavLink, {
    activeClassName: "opacity-active",
    to: "/a-bold-future/",
    className: "group relative block xl:grid xl:grid-cols-4 xl:gap-x-5 py-5 border-t border-grey-light items-start opacity-20 transition-opacity hover:opacity-100"
  }, /* @__PURE__ */ h("span", {
    className: "block mb-1 md:absolute md:top-5 md:right-full md:pr-5 text-menu-number"
  }, "04"), /* @__PURE__ */ h("span", {
    className: "col-span-3 block text-menu-title tracking-tight"
  }, "A bold future"), /* @__PURE__ */ h("span", {
    className: "hidden md:block xl:col-span-1 mt-5 xl:mt-0 text-menu-summary"
  }, "Our plans for 2021"), /* @__PURE__ */ h("svg", {
    className: "hidden lg:block absolute top-0 left-full mt-5 ml-10 2xl:ml-20 opacity-0 transition-all pointer-events-none group-hover:ml-20 2xl:group-hover:ml-40 group-hover:opacity-100",
    width: "73",
    height: "44",
    viewBox: "0 0 73 44",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("path", {
    d: "M59 1C66.7459 5.04411 72 12.929 72 22C72 31.071 66.7459 38.9559 59 43",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M14 43C6.25407 38.9559 1 31.071 1 22C1 12.929 6.25407 5.04411 14 0.999998",
    stroke: "white",
    "stroke-width": "2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M47.2 12C47.2 16.5607 51.4062 20.5714 57 20.5714V23.4286C51.4062 23.4286 47.2 27.4394 47.2 32H44.4C44.4 28.5263 46.0707 25.4948 48.6638 23.4286L15 23.4286V20.5714L48.6638 20.5714C46.0707 18.5052 44.4 15.4737 44.4 12H47.2Z",
    fill: "white"
  }))), /* @__PURE__ */ h("img", {
    className: "absolute bottom-10 right-10 w-[240px] 2xl:w-[415px] opacity-0 transition-all pointer-events-none menu-sm:group-hover:opacity-100 menu-md:group-hover:opacity-100 menu-lg:group-hover:opacity-100 menu-xl:group-hover:opacity-100 menu-2xl:group-hover:opacity-100",
    src: "/static/mountains.png",
    alt: "Mountains with moon and stars"
  }))))), /* @__PURE__ */ h("div", {
    className: `fixed top-0 left-0 w-full h-screen min-h-full xl:pl-[72px] transition-visibility duration-500 bg-grey-dark ${menuOpen ? "opacity-1 delay-0 visible" : "opacity-0 delay-200 invisible"}`
  }), /* @__PURE__ */ h("div", {
    className: `absolute z-40 left-3 right-3 md:left-5 md:right-5 bottom-0 xl:top-5 xl:bottom-5 xl:left-full xl:right-auto border-b xl:border-b-0 xl:border-r xl:transition-opacity xl:duration-500 opacity-20  ${menuOpen ? "xl:opacity-0 delay-0" : "xl:opacity-20 delay-200"}`
  }), /* @__PURE__ */ h("div", {
    className: "hidden xl:block absolute z-20 ml-[8px] bottom-[20px] left-1/2 origin-bottom-left transform -rotate-90 text-sm whitespace-nowrap"
  }, "Yoyo x B Corp"));
}
{
}
