import {h} from "../_snowpack/pkg/preact.js";
import {Switch, Route} from "../_snowpack/pkg/react-router-dom.js";
export default function Header() {
  return /* @__PURE__ */ h("header", {
    className: "hidden xl:block py-7 pl-16 pr-5"
  }, /* @__PURE__ */ h(Switch, null, /* @__PURE__ */ h(Route, {
    exact: true,
    path: "/"
  }, /* @__PURE__ */ h("div", {
    className: "float-left text-md"
  }, "01 Soul searching")), /* @__PURE__ */ h(Route, {
    path: "/what-is-b-corp"
  }, /* @__PURE__ */ h("div", {
    className: "float-left text-md"
  }, "02 What is B Corp?")), /* @__PURE__ */ h(Route, {
    path: "/certified"
  }, /* @__PURE__ */ h("div", {
    className: "float-left text-md"
  }, "03 Certified!")), /* @__PURE__ */ h(Route, {
    path: "/a-bold-future"
  }, /* @__PURE__ */ h("div", {
    className: "float-left text-md"
  }, "04 A bold future"))), /* @__PURE__ */ h("div", {
    className: "float-right text-md"
  }, "‘20 / 21’"), /* @__PURE__ */ h("div", {
    className: "w-80 mx-auto pr-11 uppercase text-xs text-center"
  }, "Annual Impact Report", /* @__PURE__ */ h("br", null), "presented by Yoyo"));
}
