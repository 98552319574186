import {h} from "../_snowpack/pkg/preact.js";
import {BrowserRouter as Router} from "../_snowpack/pkg/react-router-dom.js";
import Header from "./Header.js";
import Menu from "./Menu.js";
import Main from "./Main.js";
import Footer from "./Footer.js";
export default function Bcorp() {
  return /* @__PURE__ */ h(Router, null, /* @__PURE__ */ h("div", {
    className: "xl:pl-[72px]"
  }, /* @__PURE__ */ h(Header, null), /* @__PURE__ */ h(Menu, null), /* @__PURE__ */ h(Main, null), /* @__PURE__ */ h(Footer, null)));
}
