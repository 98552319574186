import {Fragment, h} from "../_snowpack/pkg/preact.js";
export default function BoldFuture() {
  return /* @__PURE__ */ h(Fragment, null, /* @__PURE__ */ h("div", {
    className: "container mt-24 lg:mt-36 xl:mt-72"
  }, /* @__PURE__ */ h("h1", {
    className: "text-xxl"
  }, "A bold", /* @__PURE__ */ h("br", null), "future"), /* @__PURE__ */ h("div", {
    className: "xl:grid xl:grid-cols-12 xl:gap-x-20 mt-7 md:mt-14 xl:mt-24 2xl:mt-48"
  }, /* @__PURE__ */ h("div", {
    className: "xl:col-span-3 2xl:col-span-5"
  }, /* @__PURE__ */ h("img", {
    className: "block max-w-none w-[185px] md:w-[274px] lg:w-[358px] 2xl:w-[507px] xl:-mt-3 2xl:-mt-8 pointer-events-none select-none",
    src: "/static/mountains.png",
    alt: "Mountains with moon and stars"
  })), /* @__PURE__ */ h("div", {
    className: "xl:col-span-9 2xl:col-span-7 mt-9 md:mt-12 lg:mt-52 xl:mt-0"
  }, /* @__PURE__ */ h("div", {
    className: "grid grid-cols-6 gap-x-10 border-t border-grey-light"
  }, /* @__PURE__ */ h("div", {
    className: "col-span-2 lg:col-span-3 pt-4"
  }, /* @__PURE__ */ h("h2", {
    className: "text-h3"
  }, "The plan for 2021")), /* @__PURE__ */ h("div", {
    className: "col-span-4 lg:col-span-3 pt-5"
  }, /* @__PURE__ */ h("p", null, "Our first year as a B Corp digital agency didn’t go as we had planned but we found innovative solutions to keep doing what we love. The plan for 2021 is to continue focusing on our people, our planet, and being a force for change."))))), /* @__PURE__ */ h("div", {
    className: "relative mt-36 md:mt-64 lg:mt-52 xl:mt-64"
  }, /* @__PURE__ */ h("h2", {
    className: "text-h1 2xl:w-9/12"
  }, "Our ambitions for the next 12 months"), /* @__PURE__ */ h("svg", {
    className: "absolute -z-1 top-1/2 lg:-mt-32 xl:-mt-24 2xl:-mt-14 left-1/2 lg:w-[815px] 2xl:w-auto max-w-full transform -translate-x-1/2 -translate-y-1/2",
    width: "994",
    viewBox: "0 0 994 934",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ h("g", {
    opacity: "0.3"
  }, /* @__PURE__ */ h("path", {
    d: "M827 933C918.679 933 993 724.365 993 467C993 209.635 918.679 1 827 1C735.321 1 661 209.635 661 467C661 724.365 735.321 933 827 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M587 933C678.679 933 753 724.365 753 467C753 209.635 678.679 1 587 1C495.321 1 421 209.635 421 467C421 724.365 495.321 933 587 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M707 933C798.679 933 873 724.365 873 467C873 209.635 798.679 1 707 1C615.321 1 541 209.635 541 467C541 724.365 615.321 933 707 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M467 933C558.679 933 633 724.365 633 467C633 209.635 558.679 1 467 1C375.321 1 301 209.635 301 467C301 724.365 375.321 933 467 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M347 933C438.679 933 513 724.365 513 467C513 209.635 438.679 1 347 1C255.321 1 181 209.635 181 467C181 724.365 255.321 933 347 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M227 933C318.679 933 393 724.365 393 467C393 209.635 318.679 1 227 1C135.321 1 61 209.635 61 467C61 724.365 135.321 933 227 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M767 933C858.679 933 933 724.365 933 467C933 209.635 858.679 1 767 1C675.321 1 601 209.635 601 467C601 724.365 675.321 933 767 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M527 933C618.679 933 693 724.365 693 467C693 209.635 618.679 1 527 1C435.321 1 361 209.635 361 467C361 724.365 435.321 933 527 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M647 933C738.679 933 813 724.365 813 467C813 209.635 738.679 1 647 1C555.321 1 481 209.635 481 467C481 724.365 555.321 933 647 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M407 933C498.679 933 573 724.365 573 467C573 209.635 498.679 1 407 1C315.321 1 241 209.635 241 467C241 724.365 315.321 933 407 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M287 933C378.679 933 453 724.365 453 467C453 209.635 378.679 1 287 1C195.321 1 121 209.635 121 467C121 724.365 195.321 933 287 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  }), /* @__PURE__ */ h("path", {
    d: "M167 933C258.679 933 333 724.365 333 467C333 209.635 258.679 1 167 1C75.3207 1 1 209.635 1 467C1 724.365 75.3207 933 167 933Z",
    stroke: "#E2E2E2",
    "stroke-miterlimit": "10"
  })))), /* @__PURE__ */ h("ul", {
    className: "lg:grid lg:grid-cols-2 2xl:grid-cols-3 lg:gap-x-24 mt-24 md:mt-40 lg:mt-48 2xl:mt-72 xl:w-10/12 2xl:w-full xl:ml-auto"
  }, /* @__PURE__ */ h("li", {
    className: "lg:col-span-1 border-t border-grey-light pt-4"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Regular volunteer events"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "Organizing and coordinating at least one each month for our team to attend.")), /* @__PURE__ */ h("li", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-14 md:mt-24 lg:mt-0"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Match funding scheme"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "We are introducing a Match Funding scheme where Yoyo will match every pound that an employee donates to charity.")), /* @__PURE__ */ h("li", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-14 md:mt-24 2xl:mt-0"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Support the working mums in our team"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "Adding a policy to support breastfeeding mothers to our Yoyo handbook")), /* @__PURE__ */ h("li", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-14 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Sustainable hosting initiative"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "We are launching a Sustainable Hosting Initiative. This involves identifying a provider that powers it’s data centre from 100% renewable sources.")), /* @__PURE__ */ h("li", {
    className: "lg:col-span-1  border-t border-grey-light pt-4 mt-14 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Re-forestation initiative"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "We are looking to help our clients to offset the carbon footprint of the websites we build for them by planting a calculated number of trees on their behalf.")), /* @__PURE__ */ h("li", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-14 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Sustainable and ethical banking"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "We are researching local banks, independently owned banks and members of Global Alliance for Banking on Values. Based on the research we have carried, a number of our team have already moved to bank with", " ", /* @__PURE__ */ h("a", {
    className: "underline transition-opacity hover:opacity-50",
    href: "https://www.triodos.co.uk/"
  }, "Triodos Bank"), ".")), /* @__PURE__ */ h("li", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-14 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Using our voice for good"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "Seeking opportunities to advocate for policy changes that benefit society and the environment.")), /* @__PURE__ */ h("li", {
    className: "lg:col-span-1 border-t border-grey-light pt-4 mt-14 md:mt-24"
  }, /* @__PURE__ */ h("h4", {
    className: "text-xl lg:min-h-[calc(4em*0.9)]"
  }, "Switching to Ecosia"), /* @__PURE__ */ h("p", {
    className: "mt-14 md:mt-20 xl:mt-12"
  }, "Switching to Ecosia as our default search engine. All their profits go towards climate action."))), /* @__PURE__ */ h("div", {
    className: "pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "block ml-auto w-[107px] md:w-[166px] xl:w-[200px] 2xl:w-[236px] mt-5 lg:mt-14 xl:mt-16 2xl:-mt-72 mr-10 md:mr-20 2xl:mr-44",
    src: "/static/branches.png",
    alt: "Branches and leaves"
  }))), /* @__PURE__ */ h("div", {
    className: "mt-10 md:mt-14 lg:mt-16 xl:mt-28 2xl:mt-52 pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "w-full",
    src: "/static/bold-future.jpg",
    alt: "Bridge into forest"
  })), /* @__PURE__ */ h("div", {
    className: "container"
  }, /* @__PURE__ */ h("div", {
    className: "mt-24 md:mt-36 xl:mt-48 2xl:mt-72"
  }, /* @__PURE__ */ h("h2", {
    className: "text-xxxl md:mx-[-40px] lg:mx-[-50px]"
  }, "A force for ", /* @__PURE__ */ h("span", {
    className: "block text-right"
  }, "change"))), /* @__PURE__ */ h("div", {
    className: "pointer-events-none select-none"
  }, /* @__PURE__ */ h("img", {
    className: "block w-[206px] md:w-[286px] lg:w-[314px] xl:w-[416px] 2xl:w-[487px] -ml-2.5 xl:-ml-5 mt-5 md:mt-24 lg:mt-5",
    src: "/static/butterfly.png",
    alt: "Butterfly"
  })), /* @__PURE__ */ h("div", {
    className: "grid grid-cols-6 xl:w-9/12 2xl:w-7/12 gap-x-14 ml-auto mt-14 md:mt-24 lg:mt-32 xl:mt-10 2xl:mt-42 border-t border-grey-light"
  }, /* @__PURE__ */ h("div", {
    className: "col-span-2 lg:col-span-3 pt-4"
  }, /* @__PURE__ */ h("h3", {
    className: "text-h3"
  }, "Our commitment")), /* @__PURE__ */ h("div", {
    className: "col-span-4 lg:col-span-3 pt-5"
  }, /* @__PURE__ */ h("p", null, "The commitment to our people, profit, and the planet is a step in the right direction, but we want it to go beyond the decisions we make as a business."), /* @__PURE__ */ h("p", {
    className: "mt-5"
  }, "Our hope is people will be inspired to do more where they can. We want it ingrained into our daily lives, understanding that the decisions we make play an active part in the future we want to live in. As a business and as an individual, you’re only as good as your last action, so make it count."))), /* @__PURE__ */ h("div", {
    className: "2xl:w-11/12 mt-24 md:mt-36 xl:mt-48 2xl:mt-72 mb-36"
  }, /* @__PURE__ */ h("p", {
    className: "text-xl xl:text-h2"
  }, "There’s a saying that is relevant across nearly every industry and sector on the planet; “you’re only as good as your last …” then you fill in the blank with what is relevant to you. For us, it’s “", /* @__PURE__ */ h("u", null, "action"), "”."))));
}
