import {h} from "../_snowpack/pkg/preact.js";
import {Switch, Route} from "../_snowpack/pkg/react-router-dom.js";
import BoldFuture from "./BoldFuture.js";
import Certified from "./Certified.js";
import SoulSearching from "./SoulSearching.js";
import WhatIsBcorp from "./WhatIsBcorp.js";
import NotFound from "./NotFound.js";
export default function Header() {
  return /* @__PURE__ */ h("main", {
    className: "pt-[56px] md:pt-[68px] xl:pt-0"
  }, /* @__PURE__ */ h(Switch, null, /* @__PURE__ */ h(Route, {
    exact: true,
    path: "/"
  }, /* @__PURE__ */ h(SoulSearching, null)), /* @__PURE__ */ h(Route, {
    path: "/what-is-b-corp"
  }, /* @__PURE__ */ h(WhatIsBcorp, null)), /* @__PURE__ */ h(Route, {
    path: "/certified"
  }, /* @__PURE__ */ h(Certified, null)), /* @__PURE__ */ h(Route, {
    path: "/a-bold-future"
  }, /* @__PURE__ */ h(BoldFuture, null)), /* @__PURE__ */ h(Route, {
    path: "*"
  }, /* @__PURE__ */ h(NotFound, null))));
}
